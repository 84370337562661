import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import LogoSider from "../components/logo"
import Hero from "../components/hero"
import CaseStudyList from "../components/caseStudyList"
import uuid from "react-uuid"
import Slider from "react-slick"
import HalfDescription from "../components/half/halfDescription"
import HalfNumbers from "../components/half/halfNumbers"
import TitleStyled from "../components/styles/titleStyles"
import HomeFeedStyled from "../components/styles/HomeFeedStyles"
import HomeMediaStyled from "../components/styles/HomeMediaStyles"
import myInitObject from "../ultis/variable"
import FeedBox from "../components/feedBox"
import HalfButton from "../components/half/halfButton"
import HalfImage from "../components/half/halfImage"
import SEO from "../components/seo"

const IndexPage = ({ data, pageContext }) => {
  const { homeSingleton, blogCollection } = data.swapi
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
          autoplay: true,
          speed: 2000,
          autoplaySpeed: 2000,
          cssEase: "linear",
        },
      },
    ],
  }

  return (
    <Layout pageContext={pageContext}>
      <SEO
        title={homeSingleton.meta_title}
        description={homeSingleton.meta_description}
        img={homeSingleton.meta_img.path}
        lang="en"
      />
      <Hero
        heading={homeSingleton.heading_page}
        colorHeading={homeSingleton.heading_color}
        services={homeSingleton.service}
        heroType="service"
      />

      <LogoSider />
      <section className="caseStudyList">
        {homeSingleton.caseStudy.map((item, index) => {
          const {
            heading,
            description,
            promotetText,
            linkText,
            link,
          } = item.value
          const backroundPaht = item.value.backround.path
          return (
            <CaseStudyList
              page="home"
              key={uuid()}
              number={index}
              heading={heading}
              description={description}
              promotetText={promotetText}
              linkText={linkText}
              link={link}
              backroundPaht={backroundPaht}
            />
          )
        })}
      </section>
      <section className="half">
        <HalfDescription
          backgroundColor="#000000"
          heading={homeSingleton.dmpHeading}
          headingColor="#fff"
          description={homeSingleton.dmpDescription}
          descriptionColor="#fff"
          position="left"
        />
        <HalfNumbers
          backgroundColor="#fff"
          headingColor="#000"
          descriptionColor="#000"
          position="right"
          borderColor="#000"
          numbers={homeSingleton.dmpNubmer}
        />
      </section>
      <section className="blog">
        <HomeFeedStyled>
          <div className="container">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h2
                dangerouslySetInnerHTML={{ __html: homeSingleton.blogHeading }}
              ></h2>
            </TitleStyled>
            <div className="blog__expert">
              <p className="blog__expert__heading">
                {homeSingleton.blogExpertText}
              </p>
              <div className="blog__expert__wrapper">
                {homeSingleton.blogExpert.map(item => (
                  <div className="blog__expert__item" key={uuid()}>
                    <div className="blog__expert__img">
                      <img
                        src={myInitObject.cockpitUrl + item.value.img.path}
                        alt={item.value.name}
                      />
                    </div>
                    <div className="blog__expert__descirption">
                      <p>{item.value.name}</p>
                      <p className="blog__expert__descirption__position">
                        {item.value.position}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={`blog__feed blog__feed--${blogCollection.length}`}>
              {blogCollection.map(item => (
                <FeedBox
                  key={uuid()}
                  img={myInitObject.cockpitUrl + item.feedImage.path}
                  title={item.feedTitle}
                  link={"/en/blog/" + item.slug}
                  linkText={item.feedTextButton}
                  titleSize="22"
                />
              ))}
            </div>
          </div>
        </HomeFeedStyled>
      </section>
      <section className="media">
        <HomeMediaStyled>
          <div className="container">
            <TitleStyled
              textAlign="center"
              linePosition="center"
              color="#000000"
            >
              <h2
                dangerouslySetInnerHTML={{ __html: homeSingleton.mediaHeading }}
              ></h2>
            </TitleStyled>
            <div className="rotator__wrapper">
              <Slider {...settings}>
                {homeSingleton.mediaImg.map(logo => (
                  <div key={uuid()} className="rotator__item">
                    <a href={logo.value.link} target="_blank">
                      <img
                        src={myInitObject.cockpitUrl + logo.value.img.path}
                      />
                    </a>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </HomeMediaStyled>
      </section>
      <section className="half">
        <HalfButton
          position="left"
          backgroundColor="#000"
          heading={homeSingleton.half.name}
          headingColor="#fff"
          link={homeSingleton.half.link}
          linkText={homeSingleton.half.linkText}
        />
        <HalfImage
          backgroundImage={
            myInitObject.cockpitUrl + homeSingleton.half.img.path
          }
        />
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HomeSingletonEN {
    swapi {
      homeSingleton(lang: "en") {
        meta_title
        meta_description
        meta_img {
          path
        }
        heading_page
        heading_color
        service {
          value
        }
        caseStudy {
          value
        }
        dmpHeading
        dmpDescription
        dmpNubmer {
          value
        }
        blogHeading
        blogExpertText
        blogExpert {
          value
        }
        mediaHeading
        mediaImg {
          value
        }
        half {
          name
          link
          linkText
          img {
            path
            meta
          }
        }
      }
      blogCollection(limit: 2) {
        slug
        feedTitle
        feedImage {
          path
        }
        feedTextButton
      }
    }
  }
`
